import styled from "styled-components";
import media from "styled-media-query";
import { StopMini } from "styled-icons/remix-fill/StopMini";
import Img from "gatsby-image";

export const SquareIcon = styled(StopMini)`
  height: 1.2rem;
`;

//styled components
export const TestimonialsWrapper = styled.section`
  padding-top: 2rem;
  ${media.lessThan("large")`
    padding-top: var(--default-padding-mobile);
  `}
`;
export const MainSubtitle = styled.h2`
  font-size: var(--paragraph-size);
  padding: 0 var(--default-padding-desktop);
  margin-bottom: 2rem;
  text-align: center;
  ${media.lessThan("small")`
    padding: 0 var(--default-padding-mobile);
    margin-bottom:0;
  `}
`;
export const SliderWrapper = styled.section`
  height: 100%;
  .each-slide > div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    height: 100%;
  }
  .indicator {
    z-index: 3;
    padding: 1rem;
    cursor: pointer;
    text-align: center;
    color: #fafafa;
    color: var(--esx-red);
  }
  .indicator.active {
    svg {
      transition: transform 500ms;
      transform: scale(2);
    }
    color: var(--esx-red);
  }
`;
export const Slide = styled.div`
  display: flex;
  padding: 0 var(--default-padding-desktop);
  gap: 2rem;
  ${media.lessThan("large")`
    padding: var(--default-padding-mobile);
  `}
  ${media.lessThan("small")`
    flex-direction: column-reverse;
    padding-bottom: 0;
  `}
`;
export const SliderContentWrapper = styled.div``;
export const AvatarWrapper = styled.div`
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
`;
export const Avatar = styled(Img)`
  border-radius: 3rem;
  height: 6rem;
  width: 6rem;
  ${media.lessThan("small")`
    height: 4rem;
    width: 4rem;
  `}
`;
export const AvatarTitle = styled.h1`
  color: ${(props) => props.theme.color};
  font-size: var(--menu-item-size);
  font-weight: bold;
`;
export const AvatarLabel = styled.h2`
  font-size: var(--label-size);
`;
export const SliderText = styled.div`
  margin-left: auto;
  height: auto;
  width: 100%;
`;
export const Quote = styled.blockquote`
  font-size: var(--menu-item-size);
  opacity: 0.8;
  font-style: italic;
  &::before {
    content: '"';
    position: absolute;
    font-size: 3rem;
    margin: -1rem 0rem 0rem -2rem;
    color: ${(props) => props.theme.color};
  }
  &::after {
    content: '"';
    position: absolute;
    font-size: 3rem;
    color: ${(props) => props.theme.color};
    margin: 0.1rem;
  }
  ${media.lessThan("small")`
    font-size: var(--label-size);
    &::before {
      margin: -1.5rem;
    }
    &::after {

      margin: -.2rem;
    }
  `}
`;
export const Subtitle = styled.h2`
  font-size: var(--subtitle-size);
  margin-bottom: 2rem;
  ${media.lessThan("large")`
     font-size: var(--paragraph-size);
  `}
`;

//themes
export const mainTheme = {
  color: "var(--esx-red)",
  background: "var(--dark-gradient)",
};

SliderWrapper.defaultProps = {
  theme: {
    color: "#fafafa",
    background: "var(--dark-gradient)",
  },
};
