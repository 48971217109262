import React from "react";
import "../bulma.scss";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeroSection from "../components/HeroSection";
import CTABanner from "../components/CtaBanner";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import media from "styled-media-query";

import Atitude from "../images/svgs/atitude.svg";
import Colaboracao from "../images/svgs/colaboracao.svg";
import Protagonismo from "../images/svgs/protagonismo.svg";
import Tecnologia from "../images/svgs/tecnologia.svg";
import Smiley from "../images/svgs/smiley.svg";

import ItemColumns from "../components/ItemColumns";
import Testimonials from "../components/Testimonials";
import Gptw from "../images/svgs/logo-selo-gptw.svg";

//StyledInternal GPTW
export const GptwCTAWrapper = styled.section`
height: auto;
padding: 2rem var(--default-padding-desktop);
display: flex;
flex-direction: column-reverse;
align-items: center;
${media.lessThan("medium")`
  padding: 2rem var(--default-padding-mobile);
`}
`;
export const GptwTextWrapper = styled.div`
  flex: 1;
`;
export const GptwWrapper = styled.div`
  height: 220px;
  width: 110px;
`;
export const GptwTitle = styled.h1`
color: var(--esx-red);
font-size: var(--medium-title-size);
font-weight: bold;
width: 100%;
text-align: center;
`;
export const GptwSubtitle = styled.h2`
font-size: var(--small-title-size);
width: 100%;
text-align: center;
`;

const CarreerPage = () => {
  const { carreerImg } = useStaticQuery(
    graphql`
      query {
        carreerImg: file(relativePath: { eq: "cover_cultura_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 800, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const esxThemeRed = {
    main: "#fafafa",
    color: "#fafafa",
    gradient: "linear-gradient(var(--esx-red), var(--esx-red))",
    titleColor: "#fff",
    textColor: "#fafafa",
    boldTextColor: "#fff",
  };

  const offers = [
    {
      icon: <Protagonismo width="80" height="80" />,
      title: "Protagonismo",
      description:
        "Queremos que você faça a diferença dentro da nossa equipe. As histórias de sucesso em nossos clientes são contadas por nossos colaboradores.",
    },
    {
      icon: <Colaboracao width="80" height="80" />,
      title: "Colaboração",
      description:
        "Na nossa cultura o trabalho em equipe é muito importante. Precisamos que você trabalhe em conjunto com o time para levar as melhores soluções aos nossos clientes.",
    },
    {
      icon: <Atitude width="80" height="80" />,
      title: "Atitude",
      description:
        "Fazer a diferença no universo de TI não é fácil, precisamos de pessoas dispostas a enfrentar desafios constantes, aprender e se desenvolver com a gente.",
    },
    {
      icon: <Tecnologia width="80" height="80" />,
      title: "Experiência",
      description:
        "Construa cases de sucesso da sua carreira e desenvolva ao máximo suas habilidades atuando em grandes projetos de empresas reconhecidas.",
    },
    {
      icon: <Smiley width="80" height="80" />,
      title: "Bem Estar",
      description:
        "Nesta organização não existem silos, trabalhamos felizes e nada sobrepõe o nosso compromisso com a construção de um ambiente de trabalho incrível.",
    },
  ];

  return (
    <Layout>
      <SEO title="Carreira" />

      <HeroSection
        theme={esxThemeRed}
        title="Só tecnologia não basta, falta você."
        text="A ESX acredita em ações verdadeiramente centradas em pessoas e não somente na aquisição, uso e implementação de tecnologias.<br/><br/>Quando entendemos que tudo aquilo que criamos e desenvolvemos impacta diariamente a vida das pessoas, nosso senso de responsabilidade e comprometimento aumentam, gerando uma entrega real de valor para todos aqueles que confiam em nossas soluções."
        fixedImage={carreerImg.childImageSharp.fluid}
      />
      <GptwCTAWrapper>
        <GptwTextWrapper>
          <GptwTitle>Great Place to Work 2021</GptwTitle>
          <GptwSubtitle>A ESX é uma das melhores empresas para se trabalhar no Brasil e torná-la um lugar cada vez melhor é um compromisso que renovamos todos os dias</GptwSubtitle>
        </GptwTextWrapper>
        <GptwWrapper>
          <Gptw height="auto" width="auto"/>
        </GptwWrapper>
      </GptwCTAWrapper>
      <ItemColumns
        mainTitle="Como incentivamos e suportamos as pessoas"
        mainSubtitle="O ponto forte da nossa cultura é o estímulo e suporte para que as
        pessoas se desenvolvam e passem a aprender juntos com toda a organização.
        Aqui defendemos, profundamente, a liberdade individual, abertura, e o
        respeito sobre todas as diferenças que existem.
        "
        items={offers}
        theme={{ color: "var(--esx-red)" }}
      />

      <ItemColumns
        mainTitle="Faça uma análise e veja se isso tudo também faz sentido para você."
        mainSubtitle=""
        items={[]}
        theme={{ color: "var(--esx-red)" }}
      />

      <CTABanner
        title="Venha trabalhar com a gente"
        subtitle="Uma empresa dedicada com o seu desenvolvimento pessoal e profissional"
        buttonText="Quero trabalhar na ESX"
        buttonLink="/jobs"
      />

      <Testimonials />
    </Layout>
  );
};

export default CarreerPage;
