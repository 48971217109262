import React from "react";
import * as S from "./styled";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider } from "styled-components";
import BorderedTitle from "../BorderedTitle";

const Testimonials = () => {
  const { balta, vital, alan, ramos } = useStaticQuery(
    graphql`
      query {
        balta: file(relativePath: { eq: "balta.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 800, quality: 100, fit: COVER) {
              ...GatsbyImageSharpFluid_withWebp
              src
            }
          }
        }
        vital: file(relativePath: { eq: "vital.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
              ...GatsbyImageSharpFluid_withWebp
              src
            }
          }
        }
        alan: file(relativePath: { eq: "alan.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
              ...GatsbyImageSharpFluid_withWebp
              src
            }
          }
        }
        ramos: file(relativePath: { eq: "ramos.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
              ...GatsbyImageSharpFluid_withWebp
              src
            }
          }
        }
      }
    `
  );

  const sliderPropertys = {
    indicators: true,
    transitionDuration: 300,
    duration: 5000,
    Easing: "ease",
    arrows: false,
    indicators: () => (
      <ul className="indicator">
        <S.SquareIcon />
      </ul>
    ),
  };
  return (
    <S.TestimonialsWrapper>
      <BorderedTitle title="Depoimentos" align="center" />
      <S.MainSubtitle>Mensagens de quem está ou passou por aqui</S.MainSubtitle>
      <S.SliderWrapper className="slide-container">
        <Slide {...sliderPropertys}>
          <ThemeProvider theme={S.mainTheme}>
            <S.SliderContentWrapper className="each-slide">
              <S.Slide>
                <S.AvatarWrapper>
                  <S.Avatar fluid={balta.childImageSharp.fluid} />
                  <S.AvatarTitle>André Baltieri</S.AvatarTitle>
                  <S.AvatarLabel>CEO balta.io e MVP Microsoft</S.AvatarLabel>
                </S.AvatarWrapper>
                <S.SliderText>
                  <S.Quote>
                    Já trabalhei no Brasil e nos Estados Unidos, em projetos de
                    diversos tamanhos. A esx é a melhor empresa que eu já
                    trabalhei. Sinto saudades até hoje.
                  </S.Quote>
                </S.SliderText>
              </S.Slide>
            </S.SliderContentWrapper>
          </ThemeProvider>
          <ThemeProvider theme={S.mainTheme}>
            <S.SliderContentWrapper className="each-slide">
              <S.Slide>
                <S.AvatarWrapper>
                  <S.Avatar fluid={ramos.childImageSharp.fluid} />
                  <S.AvatarTitle>Rodrigo Ramos</S.AvatarTitle>
                  <S.AvatarLabel>Head of People</S.AvatarLabel>
                </S.AvatarWrapper>
                <S.SliderText>
                  <S.Quote>
                    Estou há mais de 5 anos na ESX e posso dizer que nesse
                    período passei por vários desafios que me fizeram crescer
                    tanto profissional quanto pessoalmente! O ambiente leve e a
                    liberdade que a gente encontra aqui são coisas muito
                    difíceis de encontrar no mercado atual. E o que falar dos
                    cursos, certificações e eventos que ela patrocina pra gente?
                    Cursos de liderança, eventos de agilidade, certificações.
                    Até curso de evolução pessoal (Eneagrama) já tive a
                    oportunidade de fazer pela ESX! #AmoTrabalharNaESX
                  </S.Quote>
                </S.SliderText>
              </S.Slide>
            </S.SliderContentWrapper>
          </ThemeProvider>
          <ThemeProvider theme={S.mainTheme}>
            <S.SliderContentWrapper className="each-slide">
              <S.Slide>
                <S.AvatarWrapper>
                  <S.Avatar fluid={vital.childImageSharp.fluid} />
                  <S.AvatarTitle>Felipe Vital</S.AvatarTitle>
                  <S.AvatarLabel>Marketing</S.AvatarLabel>
                </S.AvatarWrapper>
                <S.SliderText>
                  <S.Quote>
                    É um prazer trabalhar na esx. Levo no coração tudo o que eu
                    aprendo aqui dentro, sobre as coisas que tornam o trabalho
                    muito mais agrádavel: o respeito independente da posição,
                    além da dedicação que investem no maior capital de todos: o
                    humano. Agradeço a oportunidade que me deram de ingressar no
                    time e me especializar ainda mais na área de “Marketing
                    Digital”, quando investiram em mim e no melhor curso
                    disponível no mercado. Essa empresa transformou a minha
                    vida.
                  </S.Quote>
                </S.SliderText>
              </S.Slide>
            </S.SliderContentWrapper>
          </ThemeProvider>
          <ThemeProvider theme={S.mainTheme}>
            <S.SliderContentWrapper className="each-slide">
              <S.Slide>
                <S.AvatarWrapper>
                  <S.Avatar fluid={alan.childImageSharp.fluid} />
                  <S.AvatarTitle>Alan Carlos</S.AvatarTitle>
                  <S.AvatarLabel>Arquiteto de Soluções Cloud</S.AvatarLabel>
                </S.AvatarWrapper>
                <S.SliderText>
                  <S.Quote>
                    Quando entrei na ESX, vim de outro Estado e precisava cuidar
                    da minha mudança, da minha família e de documentações, entre
                    outras questões. Tive total apoio por parte da ESX nesse
                    período, com flexibilidades, orientação, apoios de forma
                    geral que fez com que minha mudança e de minha família fosse
                    tranquila. Estou na ESX há 03 anos e trabalho em projetos
                    incríveis com grandes clientes e tecnologias emergentes de
                    mercado, com total apoio tanto na parte de alinhamento do
                    desafio do projeto como apoio em capacitação para eu
                    entregar o melhor e me sentir realizado profissionalmente.
                  </S.Quote>
                </S.SliderText>
              </S.Slide>
            </S.SliderContentWrapper>
          </ThemeProvider>
        </Slide>
      </S.SliderWrapper>
    </S.TestimonialsWrapper>
  );
};

export default Testimonials;
